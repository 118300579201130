<template>
  <div class="testimonials">

    <div v-if="$root.isTablet()">
      <h1 class="title is-2 is-size-3-mobile has-text-secondary" v-html="$t('landing.testimonials')"/>
      <div class="columns is-mobile is-multiline has-text-left is-4">
        <div class="column">
          <div class="box">
            <p class="m-5"> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/></p>
            <p class="m-5">{{ $t('landing.testimonial1') }}</p>
            <div class="columns is-mobile m-5">
              <div class="column is-narrow">
                <img src="~@/assets/landing/Cedric.jpg"/>
              </div>
              <div class="column">
                <h5 class="title is-5 has-text-primary m-0">Cédric</h5>
                <p>{{ $t('landing.triathlete') }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="box">
            <p class="m-5"> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/></p>
            <p class="m-5">{{ $t('landing.testimonial5') }}</p>
            <div class="columns is-mobile m-5">
              <div class="column is-narrow">
                <!--<img src="~@/assets/landing/Cedric.jpg"/>-->
              </div>
              <div class="column">
                <h5 class="title is-5 has-text-primary m-0">Pascal</h5>
                <p>{{ $t('landing.cyclist') }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="box">
            <p class="m-5"> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/></p>
            <p class="m-5">{{ $t('landing.testimonial3') }}</p>
            <div class="columns is-mobile m-5">
              <div class="column is-narrow">
                <!--<img src="~@/assets/landing/Cedric.jpg"/>-->
              </div>
              <div class="column">
                <h5 class="title is-5 has-text-primary m-0">Vincent</h5>
                <p>{{ $t('landing.cyclist') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-carousel v-if="$root.isMobile()"  :autoplay="false" :pause-info="false" :arrow-hover="false" :indicator="false" :repeat="false" icon-size="is-large">
      <b-carousel-item>
        <div class="box">
          <p class="m-5"> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/></p>
          <p class="m-5">{{ $t('landing.testimonial1') }}</p>
          <div class="columns is-mobile m-5">
            <div class="column is-narrow">
              <img src="~@/assets/landing/Cedric.jpg"/>
            </div>
            <div class="column">
              <h5 class="title is-5 has-text-primary m-0">Cédric</h5>
              <p>{{ $t('landing.triathlete') }}</p>
            </div>
          </div>
        </div>
      </b-carousel-item>

      <b-carousel-item>
        <div class="box">
          <p class="m-5"> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/></p>
          <p class="m-5">{{ $t('landing.testimonial5') }}</p>
          <div class="columns is-mobile m-5">
            <div class="column is-narrow">
              <!--<img src="~@/assets/landing/Cedric.jpg"/>-->
            </div>
            <div class="column">
              <h5 class="title is-5 has-text-primary m-0">Pascal</h5>
              <p>{{ $t('landing.cyclist') }}</p>
            </div>
          </div>
        </div>
      </b-carousel-item>

      <b-carousel-item>
        <div class="box">
          <p class="m-5"> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/> <b-icon icon="star" type="is-primary"/></p>
          <p class="m-5">{{ $t('landing.testimonial3') }}</p>
          <div class="columns is-mobile m-5">
            <div class="column is-narrow">
              <!--<img src="~@/assets/landing/Cedric.jpg"/>-->
            </div>
            <div class="column">
              <h5 class="title is-5 has-text-primary m-0">Vincent</h5>
              <p>{{ $t('landing.cyclist') }}</p>
            </div>
          </div>
        </div>
      </b-carousel-item>
    </b-carousel>
  </div>
</template>

<script>

export default{
  name: 'Testimonials',
}
</script>

<style lang="scss" scoped>
</style>
